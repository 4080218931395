import {getSignals} from "../api/signal-controller";
import {MESSAGE_LOAD_TIMEOUT_MS, SIGNAL_LOAD_TIMEOUT_MS} from "../constants";
import {SoundCall} from "../utils/sound/soundCall";
import {getActualMessages, MESSAGE_TYPE} from "../api/message-controller";
import logger from "../utils/logger";
const sound = new SoundCall()

const defaultState = () => ({
  me: {},
  signals: [],
  signalLoadInterval: null,
  // messages: [],
  inMessages: [],
  inConfirmations: [],
  outMessages: [],
  outConfirmations: [],
  messageLoadInterval: null,
  activeSignalNumber: 0,
  activeMessageNumber: 0.,
  soundOff: false,
  infoAlertText: '',
  infoAlertVisibility: false
})

const state = defaultState();

const mutations = {
  SET_INFO_ALERT_VISIBILITY(state, value) {
    state.infoAlertVisibility = !!value
  },
  SET_INFO_ALERT_TEXT(state, text) {
    state.infoAlertText = text
  },
  SET_SIGNALS(state, signals) {
    state.signals = signals
  },
  SET_SOUND_ON(state) {
    state.soundOff = false
    console.log('set sound on')
  },
  SET_SOUND_OFF(state){
    state.soundOff = true
    console.log('set sound off')
  },
  LOGOUT(state) {
    console.log('logout')
    state = Object.assign(state, defaultState());
    console.log('state', state)
    // Object.keys(state).forEach(k => {
    //   // console.log('logout', k, state[k], defaultState()[k])
    //   state[k] = defaultState()[k]
    // })
    // // alert('logout')

  },
  updateSignals(state, data) {
    state.signals = data
  },
  SET_SIGNAL_LOAD_INTERVAL(state, interval) {
    if (state.signalLoadInterval !== null) {
      clearInterval(state.signalLoadInterval)
    }
    state.signalLoadInterval = interval
  },
  SET_MESSAGE_LOAD_INTERVAL(state, interval) {
    if (state.messageLoadInterval !== null) {
      clearInterval(state.messageLoadInterval)
    }
    state.messageLoadInterval = interval
  },
  SET_IN_MESSAGES(state, messages) {
    state.inMessages = messages
  },
  SET_IN_CONFIRMATIONS(state, messages) {
    state.inConfirmations = messages
  },
  SET_OUT_MESSAGES(state, messages) {
    state.outMessages = messages
  },
  SET_OUT_CONFIRMATIONS(state, messages) {
    state.outConfirmations = messages
  }
}

const getters = {
  getMe(state) {
    return state.me;
  },
  getShowMainMenu(state) {
    return state.showMenu
  },
  getSoundOffStatus(state) {
    console.log('state', state.soundOff)
    return state.soundOff
  },
  getSoundTitle(state) {
    return state.soundOff ? 'Звук отключен' : 'Звук включен'
  },
  getInfoAlertVisibility(state) {
    return state.infoAlertVisibility
  },
  getInfoAlertText(state) {
    return state.infoAlertText
  },
  getFirstSignal(state) {
    if (state.signals && state.signals.length) {
      return state.signals[0] //TODO check what signal should i get
    } else {
      return null
    }
  },
  getFirstSignalDate(state) {
    let signal = state.signals && state.signals.length ? state.signals[0] : null
    if (signal) {
      return signal.earthquakeDate
    } else {
      return null
    }
  },
  getSignals(state) {
    return state.signals
  },
  getInMessages(state) {
    return state.inMessages
  },
  getInConfirmations(state) {
    return state.inConfirmations
  },
  getOutMessages(state) {
    return state.outMessages
  },
  getOutConfirmations(state) {
    return state.outConfirmations
  }
}

const actions = {
  stateLogout({commit, dispatch}) {
    dispatch('stopLoadingSignals')
    dispatch('stopLoadingMessages')
    commit('LOGOUT')
  },
  showInfoAlert({commit}, text) {
    commit('SET_INFO_ALERT_TEXT', text)
    commit('SET_INFO_ALERT_VISIBILITY', true)
  },
  setMe({ commit }, data) {
    commit('SET_ME', data)
  },
  toggleMainMenu({commit, getters}) {
    console.log('toggleMainMenu', getters.getShowMainMenu)
    commit('SHOW_MENU', !getters.getShowMainMenu)
  },
  async downloadSignals({commit}) {
    this.signals = []
    let result = await getSignals()
    // logger('signals', result)
    let signals = result.payload ? result.payload : []
    commit('SET_SIGNALS', signals)
  },
  async loadSignals({commit}){
  // async loadSignals({commit, state, getters}) {
    let result = await getSignals()
    logger('signals', result)
    commit('SET_SIGNALS', result.payload ? result.payload : [])
    console.log('this.getSoundOffStatus', this.getSoundOffStatus)
    // if (!getters.getSoundOffStatus) {
    //   console.log('play signal', new Date())
    //   sound.checkAndPlaySignals(state.signals);
    // }
  },
  async startLoadingSignals({commit, dispatch}) {
    let interval = setInterval(async () => {dispatch('loadSignals')}, SIGNAL_LOAD_TIMEOUT_MS)
    commit('SET_SIGNAL_LOAD_INTERVAL', interval)
  },
  stopLoadingSignals({commit}) {
    commit('SET_SIGNAL_LOAD_INTERVAL', null)
  },
  async getActualMessages({commit, state}, options) {
    let messages = await getActualMessages(options.type, options.date)
    switch (options.type) {
      case MESSAGE_TYPE.INPUT_MESSAGE:
        commit('SET_IN_MESSAGES', messages)
        break;
      case MESSAGE_TYPE.INPUT_CONFIRMATION:
        commit('SET_IN_CONFIRMATIONS', messages)
        break;
      case MESSAGE_TYPE.OUTPUT_MESSAGE:
        commit('SET_OUT_MESSAGES', messages)
        break;
      case MESSAGE_TYPE.OUTPUT_CONFIRMATION:
        commit('SET_OUT_CONFIRMATIONS', messages)
        break;
      default:
        console.error('some error with loaded message type')
        // state.messages = []
    }
    // this.updateLastDate(this.messages)
    if (state.inMessages.some(m => {
      console.log(!m.viewed)
      return !m.viewed
    })) {
      if (!getters.getSoundOffStatus) {
        console.log('play message', new Date())
        sound.checkAndPlayMessage(state.inMessages)
      }
    }
  },
  async loadMessages(date) {
    this.getActualMessages(MESSAGE_TYPE.INPUT_MESSAGE, date)
    this.getActualMessages(MESSAGE_TYPE.INPUT_CONFIRMATION, date)
    this.getActualMessages(MESSAGE_TYPE.OUTPUT_MESSAGE, date)
    this.getActualMessages(MESSAGE_TYPE.OUTPUT_CONFIRMATION, date)
  },
  async startLoadingMessages({commit, dispatch}, date) {
    dispatch('loadMessages', null)
    let interval = setInterval( async () => {dispatch('loadMessages', date)}, MESSAGE_LOAD_TIMEOUT_MS)
    commit('SET_MESSAGE_LOAD_INTERVAL', interval)
  },
  stopLoadingMessages({commit}) {
    commit('SET_MESSAGE_LOAD_INTERVAL', null)
  },

}

export default {
  state,
  getters,
  mutations,
  actions
}
